<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-md-12 text-center ">
        <div class="confirm-order text-center">
          <div class="order-title">
            <h2>Your Order has been received</h2>
            <img :src="'/images/check_mark_icon.png'" />
          </div>
          <div class="order-sub-title">
            <h4>Thank you for your purchase!</h4>
            <h5>
              Your order number is <strong> {{ $route.query.order_invoice }}</strong> 
            </h5>
            <p>Please have this amount ready on delivery day</p>
            <h3>&#2547;{{ $route.query.order_amount }}</h3>
            <h5> We will call you to inform about order details(at 10:00 AM to 10:00 PM)</h5>
          </div>
          <br />
          <br />
        </div>
        <div class="confirm-btn row justify-content-center">
          <div class="view-order">
            <router-link
              :to="{
                name: 'order_details',
                params: { id: $route.query.order_id },
              }"
              class="btn btn-sm order"
              >VIEW ORDER
            </router-link>
            <a class="btn btn-sm shop" href="/">CONTINUE SHOPPING </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  methods: {
    // showAlert() {
    //   this.$swal({
    //     position: "top-center",
    //     icon: "success",
    //     title:
    //       "<strong><h2>Your order has been confirmed, Thanks</h2></strong>",
    //     html:
    //       "<h4> We will call you to inform about order details(at 10:00 AM to 10:00 PM)</h4>",
    //     confirmButtonText: `<h6><a style="color:#fff;padding:20px 0px;" href="/">View More</a></h6>`,
    //     showCancelButton: true,
    //     cancelButtonText: `<h6><a style="color:#fff;padding:20px 0px;" href="/">Close</a></h6>`,
    //     cancelButtonColor: "red",
    //     duration: 5000,
    //   });
    // },
  },
};
</script>

<style>



.order-sub-title p {
    color: #828282;
}

.order-sub-title p {
    color: #828282;
}
.order-sub-title h3 {
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #AA8153;
}

.confirm-btn {
    text-align: center;
}

.order {
    padding: 8px 25px;
    font-size: 12px;
    line-height: 1.5;
    background: #AA8153;
    border-radius: 3px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-right: 60px;
}

.shop {
    padding: 8px 25px;
    font-size: 12px;
    line-height: 1.5;
    background: #ff4d03;
    border-radius: 3px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}




.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 50em !important;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
</style>
